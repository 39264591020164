
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    
















































































































































































































































































































#xy-nav-menu .ant-menu-item,
#xy-nav-menu .ant-menu-submenu {
  margin-right: var(--xy-custom-nav-multi-menu-title-gap);
  font-size: var(--xy-custom-nav-font-size);
  min-width: 48px;
  text-align: center;
}

    