
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    .container {
  width: 100vw;

  .menu {
    width: 100% !important;

    --padding-level-increment: 20px;
    --padding-level-0: 20px;
    --padding-level-1: calc(var(--padding-level-increment) + var(--padding-level-0));
    --padding-level-2: calc(var(--padding-level-increment) + var(--padding-level-1));
    --padding-level-3: calc(var(--padding-level-increment) + var(--padding-level-2));
    --padding-level-4: calc(var(--padding-level-increment) + var(--padding-level-3));


    :global {

      .t-default-menu__inner .t-menu .t-menu-group>*:not(:first-child),
      .t-default-menu__inner .t-menu .t-menu__sub>*:not(:first-child),
      .t-default-menu__inner .t-menu .t-submenu>*:not(:first-child),
      .t-default-menu__inner .t-menu>*:not(.t-menu-group):not(:first-child) {
        margin-top: 0px;
      }

      .t-default-menu__inner {
        background-color: var(--xy-custom-sidebar-bg-color);

        .t-menu__item {
          padding-left: var(--padding-level-0);
          padding-right: var(--padding-level-0);
          border-radius: 0px;
          height: 56px;
          color: #1e304a;

          .anticon {
            width: var(--xy-custom-sidebar-font-size-icon);
            height: var(--xy-custom-sidebar-font-size-icon);
            font-size: var(--xy-custom-sidebar-font-size-icon);
            margin-right: 8px;
          }
        }
      }

      .t-menu {
        padding-left: 0px;
        padding-right: 0px;
      }

      .t-menu__item.t-is-disabled {
        color: var(--td-text-color-disabled) !important;
      }






      .t-submenu {
        &[data-level="0"]>.t-menu__sub {
          --padding-left: var(--padding-level-1) !important;
        }

        &[data-level="1"]>.t-menu__sub {
          --padding-left: var(--padding-level-2) !important;
        }

        &[data-level="2"]>.t-menu__sub {
          --padding-left: var(--padding-level-3) !important;
        }

        &[data-level="3"]>.t-menu__sub {
          --padding-left: var(--padding-level-4) !important;
        }
      }

      .t-menu__sub {
        .t-menu__item {
          padding-left: var(--padding-left);
        }
      }
    }

    &.dark {
      :global {
        .t-default-menu__inner {
          .t-menu__item {
            color: #fff;
          }
        }
      }
    }
  }


}

.drawer {
  top: var(--xy-header-height) !important;

  :global {
    .t-drawer__content-wrapper {
      height: calc(100% - var(--xy-header-height)) !important;

      .t-drawer__body {
        padding: 0 !important;

        &::-webkit-scrollbar {
          display: none;
        }

        background-color: var(--xy-custom-sidebar-bg-color);
      }
    }
  }
}
    