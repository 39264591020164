
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    .container {
  width: 300px;
  height: var(--runtime-height);
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  transition: width 0.2s;
  box-shadow: 1px 0 0 0 #eee;
  padding: var(--xy-custom-sidebar-padding);
  background-color: var(--xy-custom-sidebar-bg-color);

  .search {
    width: 100%;
    padding: var(--xy-custom-sidebar-menu-search-padding) !important;

    :global {

      .t-input,
      .t-input:focus {
        box-shadow: none;
      }
    }
  }

  .menu {
    flex: 1;
    height: 0px !important;
    width: 100% !important;
    user-select: none;

    :global {

      .t-menu__item.t-is-disabled {
        color: var(--td-text-color-disabled) !important;
      }

      .t-default-menu__inner .t-menu .t-menu-group>*:not(:first-child),
      .t-default-menu__inner .t-menu .t-menu__sub>*:not(:first-child),
      .t-default-menu__inner .t-menu .t-submenu>*:not(:first-child),
      .t-default-menu__inner .t-menu>*:not(.t-menu-group):not(:first-child) {
        margin-top: 0px;
      }



      .t-default-menu__inner {
        background-color: var(--xy-custom-sidebar-bg-color);

        .t-menu--scroll {
          overflow-y: scroll;
        }

        .t-menu {
          padding: 0;
        }
      }

      .t-menu__item {
        padding-left: var(--xy-custom-sidebar-menu-item-left-padding-first);
      }

      .t-submenu {
        &[data-level="0"]>.t-menu__sub {
          --padding-left: var(--xy-custom-sidebar-menu-item-left-padding-second) !important;
        }

        &[data-level="1"]>.t-menu__sub {
          --padding-left: var(--xy-custom-sidebar-menu-item-left-padding-third) !important;
        }

        &[data-level="2"]>.t-menu__sub {
          --padding-left: var(--xy-custom-sidebar-menu-item-left-padding-forth) !important;
        }

        &[data-level="3"]>.t-menu__sub {
          --padding-left: var(--xy-custom-sidebar-menu-item-left-padding-fifth) !important;
        }
      }

      .t-menu__sub {
        background-color: var(--xy-custom-sidebar-sub-bg-color);

        .t-menu__item {
          padding-left: var(--padding-left);
        }
      }


      .t-menu--scroll {
        &::-webkit-scrollbar {
          width: var(--xy-custom-sidebar-scrollbar-size);
          height: var(--xy-custom-sidebar-scrollbar-size);
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: var(--xy-custom-sidebar-scrollbar-size);
        }

        &::-webkit-scrollbar-thumb {
          background-color: transparent;
          background-clip: border-box;
          border-radius: var(--xy-custom-sidebar-scrollbar-size);
          border: 1px solid transparent;
          transition: background-color 0.2s;
        }

        &:focus-within,
        &:hover {
          &::-webkit-scrollbar-thumb {
            background-color: var(--xy-custom-sidebar-scrollbar-thumb-bg-color);

            &:hover {
              background-color: var(--xy-custom-sidebar-scrollbar-thumb-hover-bg-color);
            }
          }
        }
      }

      .t-menu__item {
        --ripple-color: none;

        .anticon {
          color: var(--xy-custom-sidebar-icon-color);
        }

        .t-fake-arrow {
          color: var(--xy-custom-sidebar-arrow-color);
        }

        // 选中态
        &.t-is-active {

          // 菜单
          &.t-menu__item-spacer--right.t-menu__item-spacer {
            background-color: var(--xy-custom-sidebar-selected-parent-bg-color) !important;
            color: var(--xy-custom-sidebar-selected-parent-text-color);

            .anticon {
              color: var(--xy-custom-sidebar-selected-parent-icon-color);
            }

            &:hover {
              color: var(--xy-custom-sidebar-selected-parent-text-color);
              background-color: var(--xy-custom-sidebar-hover-bg-color) !important;
            }
          }

          // 链接
          &:not(.t-menu__item-spacer) {
            background-color: var(--xy-custom-sidebar-selected-bg-color) !important;
            font-weight: var(--xy-custom-sidebar-selected-text-font-weight);

            .anticon {
              color: var(--xy-custom-sidebar-selected-icon-color);
            }

            &:hover,
            & {

              &,
              & .t-menu__content {
                color: var(--xy-custom-sidebar-selected-text-color);

              }
            }
          }
        }

        &:not(.t-is-active) {
          color: var(--xy-custom-sidebar-text-color);

          &:hover {
            background-color: var(--xy-custom-sidebar-hover-bg-color) !important;
            color: var(--xy-custom-sidebar-hover-text-color);
          }

          &:active {
            background-color: var(--xy-custom-sidebar-active-bg-color) !important;
            color: var(--xy-custom-sidebar-active-text-color);
          }
        }
      }


    }

  }

  &.dark {
    .identity {
      color: rgba(255, 255, 255, 0.48);
    }
  }

  .identity {
    width: 100%;
    text-align: center;
    padding: 16px 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.48);
  }

  .dragHost {
    position: absolute;
    top: 0;
    width: 4px;
    height: 100%;
    margin: 0 -2px;
    z-index: 1;
    right: 0;
    cursor: ew-resize;

    &:hover {
      width: 6px;
      margin: 0 -3px;
      background: rgba(#fff, 0.06);
    }

  }
}

.container {
  :global {
    .t-default-menu .t-default-menu__inner .t-menu {
      .t-menu__sub {
        &.t-is-opened {
          margin-top: 0px;
          margin-bottom: var(--xy-custom-sidebar-sub-menu-bottom-margin);

          .t-menu__item {
            margin: var(--xy-custom-sidebar-menu-open-item-margin);
          }
        }
      }

      .t-menu__item {
        height: var(--xy-custom-sidebar-menu-item-height);
        width: var(--xy-custom-sidebar-menu-item-width);
        margin: var(--xy-custom-sidebar-menu-item-margin);
        border-radius: var(--xy-custom-sidebar-menu-item-border-radius);
        padding-right: var(--xy-custom-sidebar-menu-item-right-padding);

        font-size: var(--xy-custom-sidebar-font-size-second);

        &.t-is-opened {
          margin: var(--xy-custom-sidebar-menu-open-item-margin);
        }

        &[data-level="0"] {
          font-size: var(--xy-custom-sidebar-font-size-first);
        }

        &.t-menu__item-spacer--right {
          .t-menu__content {
            padding-right: var(--xy-td-custom-sidebar-menu-item-text-right-padding-arrow);
          }
        }

        .anticon {
          width: var(--xy-custom-sidebar-font-size-icon);
          height: var(--xy-custom-sidebar-font-size-icon);
          font-size: var(--xy-custom-sidebar-font-size-icon);
          margin-right: 8px;
        }

      }

    }
  }
}
    