
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    







































































































































































































































































































































.nav-switch {
  margin: var(--xy-custom-nav-widget-margin);
  &_button {
    border-radius: 14px;
    padding: 5px 10px;
    cursor: pointer;
  }
  &_button:hover{
    color: var(--xy-custom-nav-hover-color);
  }
  &_button.ant-popover-open {
    color: var(--xy-custom-nav-active-color);
  }
  &_icon {
    display: var(--xy-custom-nav-widget-icon-display);
    font-size: 12px;
    transform: rotate(0deg);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &-active {
      transform: rotate(180deg);
    }
  }
  &_options {
    cursor: pointer;
  }
}


    