
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    .icon {
  width: min-content;

  &.hoverAble {
    padding: 4px;
    cursor: pointer;

    &:hover {
      background: var(--hover-bg-color);
    }
  }
}
    