
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    





















































































































































































































.env-select {
  margin: var(--xy-custom-nav-env-select-margin);
  &_button {
    height: 28px;
    border-radius: 14px;
    padding: 5px 0;
    cursor: pointer;
    white-space: nowrap;
  }
  &_button:hover{
    color: var(--xy-custom-nav-hover-color);
  }
  &_button-active {
    color: var(--xy-custom-nav-active-color);
  }
  &_icon {
    margin-left: 4px;
  }
  &_options {
    cursor: pointer;
  }
}

    