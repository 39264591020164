
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    .nav {
  z-index: 1001;
  height: var(--xy-header-height);
  line-height: var(--xy-header-height);
  padding: 0px 12px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 8px 0 rgba(0, 21, 41, 0.1);
  background: var(--xy-custom-nav-bg-color);
  color: var(--xy-custom-nav-color);
  --gap-size: 16px;
  --max-title-width: 30vw;
  --icon-size: 20px;

  .drawer {
    height: 100%;

    :global {
      .t-drawer__content-wrapper {
        height: 100% !important;

        .t-drawer__body {
          color: var(--xy-custom-nav-color);

          &::-webkit-scrollbar {
            display: none;
          }

          background-color: var(--xy-custom-sidebar-bg-color);
        }
      }
    }
  }

  .icon {
    font-size: var(--icon-size);
    height: var(--icon-size);
    width: var(--icon-size);
    box-sizing: content-box;
    padding: 4px;

    &.bigger {
      use {
        transform: scale(1.3);
        transform-origin: center;
      }
    }
  }

  .logo {
    width: 36px;
    height: 36px;
    margin: 0 var(--gap-size);
  }

  .name {
    max-width: var(--max-title-width);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
  }

  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .left {
    :global {
      .home {
        padding: 0;
      }
    }
  }
}

.label {
  padding-right: 16px;
  white-space: nowrap;
}
    