
    $themeColor: #7F0020;
    $wujiRed: #7F0020;
    $tencentBlue: #7F0020;
    @import "@/styles/global-var.scss";
    .styleReset {
  --color: var(--xy-custom-nav-drop-option-text-color);

  :global {

    .ant-menu {
      border: none !important;
    }

    .ant-menu-submenu-title,
    .ant-menu-item {
      margin: var(--xy-custom-nav-drop-option-margin) !important;
    }

    [data-active='1']>.ant-menu-submenu-title,
    [data-active='1'].ant-menu>.ant-menu-submenu>.ant-menu-submenu-title,
    .ant-menu-item:has([data-active='1']) {
      color: var(--xy-custom-nav-drop-option-selected-text-color) !important;
      background-color: var(--xy-custom-nav-drop-option-selected-bg-color) !important;
    }
  }


  :global {


    .ant-menu-submenu-title,
    .ant-menu-item {
      color: var(--color) !important;

      .ant-menu-submenu-arrow {

        &::before,
        &::after {
          background: var(--color) !important;
        }
      }

      &:hover {
        color: var(--xy-custom-nav-drop-option-hover-text-color) !important;
        background-color: var(--xy-custom-nav-drop-option-hover-bg-color) !important;
      }

      &:active {
        color: var(--xy-custom-nav-drop-option-active-text-color) !important;
        background-color: var(--xy-custom-nav-drop-option-active-bg-color) !important;
      }
    }

    .ant-menu-submenu-open {
      color: var(--color) !important;
    }
  }
}

.popover {
  &.hasTag {
    :global {
      .ant-popover-inner {
        width: calc(100% + 15px);
      }
    }
  }
}
    